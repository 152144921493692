const PROJECTS = {

  outsideAgitators: {
    projectDetails: {
      alias: 'outsideAgitators',
      name: 'Outside Agitators',
      showcase: 'Audio',
      brief: `Debut LP by local Seattle act "Octopus Attacks Shark!!"`,
      description: 'Recording and editing services provided in partnership with Soundhouse studios and veteran studio professionals Mike Sebring and Jack Endino. Tracked guitars, vocals, and additional overlays. Mixed band-provided samples for interludes and layered vocals.',
      descriptionBullets: [
        "Tracked guitars, vocals, and additional overlays.",
        "Mixed band-provided samples for interludes and layered vocals."
      ],
      skills: [
        "Reaper",
        "Shopify"
      ],
      links: [
        {
          label: 'Official Website',
          target: 'https://www.octopus-attacks-shark.com/pages/music.html',
          icon: 'oas-logo'
        },
        {
          label: 'Spotify',
          target: 'https://open.spotify.com/album/2vzvr74t2aGSgBoR3Twlwf',
          icon: 'logo-spotify'
        },
        {
          label: 'iTunes',
          target: 'https://music.apple.com/us/album/killing-floor/1743129721?i=1743129725',
          icon: 'logo-iTunes'
        }
      ],
      thumbnailImage: {
        source: 'oas-logo',
        alt: 'OAS Band Logo'
      },
      backgroundImage: {
        source: 'oas-outside-agitators',
        alt: 'Outside Agitators Album Art'
      },
      detailImage: {
        source: 'oas-site-title',
        alt: 'OAS Band Title'
      }
    }
  },


  fossArmory: {
    projectDetails: {
      alias: 'fossArmory',
      name: 'FOSS Armory',
      showcase: 'Game Development',
      brief: `Third-person shooter and showcase for the open-source works of the PY2A community.`,
      description: `Inspired by the Defense Distributed movement, "FOSS Armory" serves as an interactive showroom for the creative works of DIY armourers JStark1809, IvanTTroll, AreWeCoolYet?, Booligan Airsoft, and the ever-growing list of open-source PY2A contributors. It provides a safe and free environment for their works to be compared, analyzed, and tested without navigating the hassles of local restrictions and extensive expensive builds. Developed and tested in-house at ToasterCat Studios and released under the Creative Commons license for all to consume and re-distribute.`,
      skills: [
        "Unity",
        "Fusion360",
        "GameLift"
      ],
      links: [
        {
          label: 'GitHub',
          target: 'https://github.com/Dirker27/FossArmory',
          icon: 'logo-github'
        }
      ],
      thumbnailImage: {
        source: 'logo-defense-distributed',
        alt: 'Defense Distributed Logo'
      },
      backgroundImage: {
        source: 'foss-background',
        alt: 'FOSS Armory'
      },
      detailImage: {
        source: 'foss-background',
        alt: 'FOSS Armory'
      }
    }
  },


  crudeMirror: {
    projectDetails: {
      alias: 'CrudeMirror',
      name: 'Crude Mirror Media',
      showcase: 'Business Development',
      brief: `"A Poorly Edited Editorial" - Multimedia pop culture blog powered by an SEO Ad Revenue model.`,
      description: `"Crude Mirror Media" was launched by ToasterCat Studios in April 2021 alongside a complete suite of analytics and revenue tracking tools.`,
      skills: [
        "WordPress",
        "AdSense",
        "GSuite",
        "Lightsail"
      ],
      links: [
        {
          label: 'Live Site',
          target: 'https://www.crude-mirror.com',
          icon: 'logo-website'
        },
      ],
      thumbnailImage: {
        source: 'crude-mirror-logo',
        alt: 'Crude-Mirror Logo'
      },
      backgroundImage: {
        source: 'crude-mirror-background',
        alt: 'Crude-Mirror Banner'
      },
      detailImage: {
        source: 'crude-mirror-site-screenshot',
        alt: 'Crude-Mirror Site'
      }
    }
  },


  strongarm: {
    projectDetails: {
      alias: 'Strongarm',
      name: 'Strongarm Digital Marketing',
      showcase: 'Web Development',
      brief: `Business portfolio and web presence for a local digital marketing provider specializing in SEO and market segment presence.`,
      description: `A simple responsive website launched with custom CSS and JS elements using minimal-cost architecture ($0.12/mo) matching strict client specifications for layout, copy, and look-and-feel.`,
      skills: [
        "HTML",
        "CSS",
        "S3",
        "Route53",
        "CloudFront"
      ],
      links: [
        {
          label: 'Live Site',
          target: 'https://www.strongarmdigitalmarketing.com',
          icon: 'logo-website'
        }
      ],
      thumbnailImage: {
        source: 'strongarm-logo',
        alt: 'Strongarm Logo'
      },
      backgroundImage: {
        source: 'strongarm-background',
        alt: 'Strongarm Site'
      },
      detailImage: {
        source: 'strongarm-site-screenshot',
        alt: 'Strongarm Site'
      }
    }
  },


  oasWebsite: {
    projectDetails: {
      alias: 'oasWebsite',
      name: 'Octopus Attacks Shark!!',
      showcase: 'Web Development',
      brief: `E-Commerce font-end and music portfolio for local punk act - "Octopus Attacks Shark!!".`,
      description: `Simple static website designed and developed from the ground-up by ToasterCat Studios. Portfolio website launched using minimal-cost architecture and integrated with 3rd party e-commerce with custom CSS to provide a fluent look-and-feel across both sites.`,
      skills: [
        "HTML",
        "CSS",
        "S3",
        "Route53",
        "CloudFront",
        "Shopify"
      ],
      links: [
        {
          label: 'Portfolio',
          target: 'https://www.octopus-attacks-shark.com',
          icon: 'oas-logo'
        },
        {
          label: 'Store',
          target: 'https://merch.octopus-attacks-shark.com',
          icon: 'oas-logo'
        }
      ],
      thumbnailImage: {
        source: 'oas-site-title',
        alt: 'OAS Logo'
      },
      backgroundImage: {
        source: 'oas-background',
        alt: 'OAS Band'
      },
      detailImage: {
        source: 'oas-site-screenshot',
        alt: 'OAS Site'
      }
    }
  },


  umaWebsite: {
    projectDetails: {
      alias: 'umaWebsite',
      name: 'Ugliest Man Alive [U.M.A]',
      showcase: 'Web Development',
      brief: `Custom portfolio site and brand press pack for local post-metal act - Ugliest Man Alive [U.M.A]`,
      description: `Web prescence and branding designed exclusively by ToasterCat Studios. Re-launched using existing 3rd party platform to facilitate dynamic content management and e-commerce integration. All brand and multimedia assets created in-house to satisfy client's desired look-and-feel.`,
      skills: [
        "Squarespace",
        "Photoshop",
        "Premiere"
      ],
      links: [
        {
          label: 'UMA Portfolio',
          target: 'https://ugliest-man-alive.com/',
          icon: 'logo-website'
        }
      ],
      thumbnailImage: {
        source: 'uma-logo',
        alt: 'UMA Logo'
      },
      backgroundImage: {
        source: 'uma-background',
        alt: 'UMA Band'
      },
      detailImage: {
        source: 'uma-machine-god',
        alt: 'UMA MachineGod//Suicide'
      }
    }
  },


  pixHell: {
    projectDetails: {
      alias: 'pixHell',
      name: 'PixHell',
      showcase: 'Game Development',
      brief: `A rogue-like bullet hell game for Android using custom motion controlls and procedural enemy generation.`,
      description: `ToasterCat Studios' mobile debut. Developed entirely in native Android using custom rendering and peripheral interpolation. All audio and multimedia assets generated in-house. Featured finalist at Irvine Mobile Game Jam 2013.`,
      skills: [
        "Android",
        "AmazonCoins",
        "Reaper"
      ],
      links: [
        {
          label: 'Appstore',
          target: ' https://www.amazon.com/dp/B00DPLJIOU',
          icon: 'logo-amazon'
        },
        {
          label: 'itch.io',
          target: '#',
          icon: 'logo-itch-io'
        },
        {
          label: 'GitHub',
          target: 'https://github.com/bmaxwell921/PixelHellProd/tree/master',
          icon: 'logo-github'
        }
      ],
      thumbnailImage: {
        source: 'pixhell-logo',
        alt: 'PixHell Logo'
      },
      backgroundImage: {
        source: 'pixhell-background',
        alt: 'PixHell'
      },
      detailImage: {
        source: 'pixhell-flyer',
        alt: 'PixHell'
      }
    }
  },


  wraithSquadron: {
    projectDetails: {
      alias: 'wraithSquadron',
      name: 'Star Wars: Wraith Squadron',
      showcase: 'Game Development',
      brief: `A fan remake of the classic "Star Wars: Rogue Squadron" flight system using modern engines and creative commons assets.`,
      description: ``,
      skills: [
        "Unity",
        "Unreal"
      ],
      links: [
        {
          label: 'itch.io',
          target: '#',
          icon: 'logo-itch-io'
        },
        {
          label: 'GitHub',
          target: 'https://github.com/Dirker27/WraithSquadron',
          icon: 'logo-github'
        }
      ],
      thumbnailImage: {
        source: 'wraith-logo',
        alt: 'Wraith Squadron Logo'
      },
      backgroundImage: {
        source: 'wraith-background',
        alt: 'Wraith Squadron'
      }
    }
  },


  chickMagnet: {
    projectDetails: {
      alias: 'ChickMagnet',
      name: 'Chick Magnet',
      showcase: 'Game Development',
      brief: `2.5-D Action-platformer starring a lost little toy chick trying to escape a delapidated toy factory using physics, magnetism, and wit.`,
      description: `Developed by a team of 9 over a rapid development cycle of 4 months. Implemented using custom physics, rigging deformations, and destructable environment assets. Featured finalist at VT Game Expo 2014.`,
      skills: [
        "Unity",
        "Fusion360",
        "Maya",
        "Photoshop",
        "Reaper"
      ],
      links: [
        {
          label: 'itch.io',
          target: '#',
          icon: 'logo-itch-io'
        },
        {
          label: 'GitHub',
          target: 'https://github.com/edeesis/Chick-Magnet',
          icon: 'logo-github'
        }
      ],
      thumbnailImage: {
        source: 'chick-magnet-logo',
        alt: 'Chick Magnet Logo'
      },
      backgroundImage: {
        source: 'chick-magnet-flyer',
        alt: 'Chick Magnet Flyer'
      }
    }
  },


  umaAlbum: {
    projectDetails: {
      alias: 'umaAlbum',
      name: 'Of Man and Nature',
      showcase: 'Audio',
      brief: `Debut LP for local Seattle post-metal act "Ugliest Man Alive [U.M.A]".`,
      description: `End-to-end production, recording, mixing, and mastering provided to client requiring experimental recording techniques and extensive overlays. Recorded in 4 different locations over an iterative creative process.`,
      skills: [
        "Reaper"
      ],
      links: [
        {
          label: 'Official Website',
          target: 'https://ugliest-man-alive.com',
          icon: 'uma-logo'
        },
        {
          label: 'Bandcamp',
          target: 'https://ugliest-man-alive.bandcamp.com/',
          icon: 'logo-bandcamp'
        },
        {
          label: 'Soundcloud',
          target: 'https://soundcloud.com/ugliest-man-alive/sets/of-man-and-nature',
          icon: 'logo-soundcloud'
        }
      ],
      thumbnailImage: {
        source: 'uma-logo',
        alt: 'UMA Logo'
      },
      backgroundImage: {
        source: 'tcstudio-background',
        alt: 'UMA Band'
      },
      detailImage: {
        source: 'uma-redacted',
        alt: 'UMA [redacted]'
      }
    }
  },


  moxel: {
    projectDetails: {
      alias: 'Moxel',
      name: 'Moxel Hooks',
      showcase: 'Web Development',
      brief: `Custom client commission: 3D Modelling, Slicing, and Printing ergonomic croquet hooks for long-term use.`,
      description: ``,
      skills: [
        "FDM",
        "Cura",
        "Fusion360"
      ],
      links: [
        {
          label: 'Store',
          target: '#',
          icon: 'logo-website'
        },
        {
          label: 'Ebay',
          target: '#',
          icon: 'logo-ebay'
        },
        {
          label: 'Amazon',
          target: '#',
          icon: 'logo-amazon'
        }
      ],
      thumbnailImage: {
        source: 'moxel-logo',
        alt: 'Moxel Logo'
      },
      backgroundImage: {
        source: 'moxel-background',
        alt: 'Moxel Hook Slicing Render'
      },
      detailImage: {
        source: 'moxel-logo-text',
        alt: 'Moxel LLC'
      }
    }
  },


  lizzie: {
    projectDetails: {
      alias: 'lizzie',
      name: 'The Lizzie',
      showcase: '3D Printing',
      brief: `Custom operational Nerf(TM) blaster comissioned compatible with Worker(TM) magazines and darts.`,
      description: ``,
      skills: [
        "FDM",
        "Cura",
        "Fusion360"
      ],
      links: [
        {
          label: 'Store',
          target: '#',
          icon: 'logo-website'
        },
        {
          label: 'Ebay',
          target: '#',
          icon: 'logo-ebay'
        },
        {
          label: 'Amazon',
          target: '#',
          icon: 'logo-amazon'
        }
      ],
      thumbnailImage: {
        source: 'logo-nerf',
        alt: 'TC Logo'
      },
      backgroundImage: {
        source: 'lizzie-profile',
        alt: 'Lizzie Assembly'
      },
      detailImage: {
        source: 'lizzie-tinker',
        alt: 'Lizzie Model'
      }
    }
  },


  tcPrints: {
    projectDetails: {
      alias: 'TCPrints',
      name: 'ToasterCat Print Shop',
      showcase: '3D Printing',
      brief: `E-Commerce platform dedicated to the distribution of custom and licensed 3D printed products to a global audience.`,
      description: ``,
      skills: [
        "FDM",
        "Cura",
        "Fusion360",
        "Shopify",
        "Ebay",
        "Amazon"
      ],
      links: [
        {
          label: 'Store',
          target: '#',
          icon: 'logo-website'
        },
        {
          label: 'Ebay',
          target: '#',
          icon: 'logo-ebay'
        },
        {
          label: 'Amazon',
          target: '#',
          icon: 'logo-amazon'
        }
      ],
      thumbnailImage: {
        source: 'tc-logo',
        alt: 'TC Logo'
      },
      backgroundImage: {
        source: 'tcprint-background',
        alt: 'TC Print Shop'
      },
      detailImage: {
        source: 'tcprint-product',
        alt: 'TC Print Product Rotation'
      }
    }
  }
};

export default PROJECTS;
