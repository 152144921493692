import ContactForm from '../../components/ContactForm/ContactForm';
import ContactInfoBar from '../../containers/ContactSection/ContactSection';

export default function ContactPage() {
  return (
    <>
      <ContactForm />
      <ContactInfoBar />
    </>
  );
}
